

















































































































































































































































import {Component, Prop, Watch} from 'vue-property-decorator';
import InnerChatsList from '@/components/InnerChatsList.vue';
import SearchView from '@/components/SearchView.vue';
import {archiveStore} from '@/store/modules/archive/ArchiveStore';
import {copyTextToClipboard, unsubscribeSafe} from '@/utils/helpers';
import ArchiveCaseListItem from '@/components/ArchiveCaseListItem.vue';
import ImageMessage from '@/components/messages/ImageMessage.vue';
import StandardMessage from '@/components/messages/StandardMessage.vue';
import RequestPersonalDataMessage from '@/components/messages/RequestPersonalDataMessage.vue';
import PersonAddedToChatMessage from '@/components/messages/PersonAddedToChatMessage.vue';
import ReplyMessage from '@/components/messages/ReplyMessage.vue';
import PersonalDataResultMessage from '@/components/messages/PersonalDataResultMessage.vue';
import LocationMessage from '@/components/messages/LocationMessage.vue';
import BusinessCardMessage from '@/components/messages/BusinessCardMessage.vue';
import SystemMessage from '@/components/messages/SystemMessage.vue';
import AvatarWithStatus from '@/components/AvatarWithStatus.vue';
import GroupAvatar from '@/components/GroupAvatar.vue';
import ChatInfo from '@/components/ChatInfo.vue';
import Notifications from '@/components/mixins/Notifications';
import {mixins} from 'vue-class-component';
import axios from '@/plugins/axios';
import {profileStore} from '@/store/modules/profile';
import {Unsubscribe} from 'firebase/firestore';
import {chatStore} from '@/store/modules/chat/ChatStore';
import EmptyStage from '@/components/EmptyState.vue';
import ToolTip from '@/components/custom/ToolTip.vue'

Component.registerHooks(['beforeRouteEnter', 'beforeRouteUpdate', 'beforeRouteLeave'])

@Component({
  name: 'archive',
  components: {
    ChatInfo,
    GroupAvatar,
    AvatarWithStatus,
    SystemMessage,
    BusinessCardMessage,
    LocationMessage,
    PersonalDataResultMessage,
    ReplyMessage,
    PersonAddedToChatMessage,
    RequestPersonalDataMessage,
    StandardMessage, ImageMessage, ArchiveCaseListItem, SearchView, InnerChatsList, EmptyStage, ToolTip
  }
})
export default class Archive extends mixins(Notifications) {
  @Prop() archiveId?: string
  @Prop() type?: string;
  @Prop() subtype?: string;

  showError: boolean = false;
  timeout: number = 3000;

  exportMode: boolean = false
  srcArchive: any[] = []
  dialogExportResult: boolean = false
  drawerChatInfo: boolean = false
  chatInfoWindowKey: string = ''
  unsubscribeCaseNotes: Unsubscribe | null = null;
  unsubscribeCustomerNotes: Unsubscribe | null = null;
  emptyIcon: string = ''
  emptyTitle: string = ''
  emptyDescription: string = ''

  userId?: string | null = profileStore.t2bUser?.id

  predicatePersonal = (archive) => archive.memberIDs?.includes(this.userId);
  predicateAll = (archive) => !archive.memberIDs?.includes(this.userId);

  get isAll() {
    return this.subtype === 'all'
  }

  get archives() {
    return this.isAll
        ? archiveStore.archiveData.filter(this.predicateAll)
        : archiveStore.archiveData.filter(this.predicatePersonal)
  }

  get archiveItems() {
    return this.archives.map((item) => {
      return {...item, checked: false}
    }).sort((a, b) => {
      const atime = a.createdDate && a.createdDate.toMillis()
      const btime = b.createdDate && b.createdDate.toMillis()
      return atime === btime ? 0 : atime > btime ? -1 : 1
    })
  }

  get emptyArchive() {
    return !this.archives.length
  }

  get selectedArchive() {
    return archiveStore.selectedArchive
  }

  get isGroupChat() {
    return this.selectedArchive?.subtype > 1;
  }

  get imageUrl() {
    if (!this.selectedArchive) {
      return null
    }
    const customer = this.selectedArchive.customer;
    const photoUrl = customer && customer.photoUrl;
    return photoUrl && photoUrl.normal
  }

  get imagesUrls() {
    if (!this.selectedArchive) {
      return null
    }
    return Object.values(this.selectedArchive.members)
        .filter((item: any) => item && !!item.photoUrl)
        .map((item: any) => item.photoUrl.normal);
  }

  get archiveTitle() {
    return this.selectedArchive?.customer?.name
  }

  get checkedForExport() {
    return this.archiveItems.filter((item) => item.checked)
  }

  get exporting() {
    return archiveStore.exporting
  }

  get exportError() {
    return archiveStore.exportError
  }

  get exportResult() {
    return archiveStore.exportResult
  }

  get caseMessages() {
    return archiveStore.caseMessages
  }

  get isEmpty() {
    return !this.srcArchive?.length
  }

  isForwardMessage(message: any) {
    const app: any = this
    return message.data?.type === app.$consts.MESSAGE_TYPE_FORWARD
  }

  isForwardedImage(message: any) {
    const app: any = this
    return message.data?.forwardedMessage?.type === app.$consts.MESSAGE_TYPE_IMAGE
  }

  isStandardMessage(message: any) {
    const app: any = this
    return message.data?.type === app.$consts.MESSAGE_TYPE_STANDARD || message.data?.sender?.type === 0
  }

  isRequestDataMessage(message: any) {
    const app: any = this
    return message.data?.type === app.$consts.MESSAGE_TYPE_REQUEST_PERSONAL_DATA
  }

  isRequestDataResultMessage(message: any) {
    const app: any = this
    return message.data?.type === app.$consts.MESSAGE_TYPE_PERSONA_DATA_RESULT
  }

  isImageMessage(message: any) {
    const app: any = this
    return message.data?.type === app.$consts.MESSAGE_TYPE_IMAGE
  }

  isAddMemberMessage(message: any) {
    const app: any = this
    return message.data?.type === app.$consts.MESSAGE_TYPE_ADD_PERSON_TO_GROUP
  }

  isReplyMessage(message: any) {
    const app: any = this
    return message.data?.type === app.$consts.MESSAGE_TYPE_REPLY_TO
  }

  isLocationMessage(message: any) {
    const app: any = this
    return message.data?.type === app.$consts.MESSAGE_TYPE_LOCATION
  }

  isBusinessCardMessage(message: any) {
    const app: any = this
    return message.data?.type === app.$consts.MESSAGE_TYPE_BUSINESS_CARD
  }

  @Watch('archiveItems')
  onArchiveItemsChanged(after, before) {
    this.srcArchive = Object.assign([], after);
  }

  @Watch('exportMode')
  onExportModeChanged(after: boolean, before: boolean) {
    if (!after) {
      this.srcArchive.forEach((item) => item.checked = false)
    }
  }

  @Watch('exportResult')
  onExportResultChanged(after, before) {
    if (after !== before) {
      this.dialogExportResult = true
    }
  }

  @Watch('exportError')
  onErrorChanged(after, before) {
    if (after !== before) {
      this.showIssue(after)
    }
  }

  onCaseNotes() {
    this.chatInfoWindowKey = 'caseNotes'
    this.drawerChatInfo = true
  }

  onChatInfo() {
    this.chatInfoWindowKey = 'info'
    this.drawerChatInfo = true
  }

  onViewPersonalData() {

  }

  async onShareArchive() {
    try {
      const shortLink = await archiveStore.shareArchive();
      await copyTextToClipboard(shortLink);
      this.showInfo('Archive link copied to clipboard');
    } catch (err: any) {
      this.showIssue(err.message);
    }
  }

  showAvatar(index: number, message: any) {
    const prevMessage = this.caseMessages[index - 1];
    const prevSenderUid = prevMessage && prevMessage.data?.sender?.uid;
    const nextMessage = this.caseMessages[index + 1];
    const nextSenderUid = nextMessage && nextMessage.data?.sender?.uid;
    if (!prevSenderUid && !nextSenderUid) {
      return true;
    } else {
      return nextSenderUid !== message.data?.sender?.uid;
    }
  }

  messageKind(index, messages) {
    const prevIndex = index - 1
    const nextIndex = index + 1

    const prevMsg = messages[prevIndex]
    const nextMsg = messages[nextIndex]

    const currentUid = messages[index].data.sender.uid;
    const prevUid = prevMsg && prevMsg.data.sender.uid;
    const nextUid = nextMsg && nextMsg.data.sender.uid;

    return [currentUid === prevUid, currentUid === nextUid];
  }

  search(term) {
    if (!term) {
      this.srcArchive = Object.assign([], this.archiveItems);
      return;
    }
    const searchString = term.toLowerCase().replace('  ', ' ')
    this.srcArchive = this.archiveItems.filter(
        (item) => item.customer?.name?.toLowerCase()?.replace('  ', ' ').includes(searchString)
            || item.associate?.name?.toLowerCase()?.replace('  ', ' ').includes(searchString));
  }

  selected(id): boolean {
    return this.selectedArchive?.id === id;
  }

  onArchiveItemSelected(item: any) {
    if (!this.selected(item.id)) {
      this.$router.push({name: this.$route.name!, params: {archiveId: item.id}, query: this.$route.query});
    }
  }

  async onExportArchive() {
    await archiveStore.exportArchive(this.checkedForExport.map((item) => item.id))
  }

  forceFileDownload(response) {
    const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', '')
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  async onDownloadPDF() {
    try {
      const response = await axios({
        method: 'get',
        url: this.exportResult.pdf,
        responseType: 'arraybuffer'
      })
      this.forceFileDownload(response)
    } catch (err) {
      this.showIssue('Failed to download PDF');
    }
    this.dialogExportResult = false
    this.exportMode = false
  }

  async onCopyPDFLink() {
    try {
      await copyTextToClipboard(this.exportResult.pdf);
      this.showInfo('PDF report link has been copied');
    } catch (err) {
      this.showIssue('Failed to copy PDF report link');
    }
    this.dialogExportResult = false
    this.exportMode = false
  }

  async onCopyHTMLLink() {
    try {
      await copyTextToClipboard(this.exportResult.html);
      this.showInfo('HTML report link has been copied');
    } catch (err) {
      this.showIssue('Failed to copy HTML report link');
    }
    this.dialogExportResult = false
    this.exportMode = false
  }

  onCloseExportResult() {
    this.dialogExportResult = false
    this.exportMode = false
  }

  unsubscribeAll() {
    unsubscribeSafe([this.unsubscribeCaseNotes, this.unsubscribeCustomerNotes])
  }

  async init(archiveId: string, query: any) {
    await archiveStore.selectArchive({archiveId: archiveId, ...query})
    await archiveStore.loadCaseMessages()
    this.unsubscribeCaseNotes = await chatStore.loadCaseNotes(true)
    this.unsubscribeCustomerNotes = await chatStore.loadCustomerNotes(true);
  }

  initEmpty() {
    const name = this.$route.name
    //const type = this.$route.query.type;
    const subtype = this.$route.query.subtype;
    switch (name) {
      case 'archive':
      case 'profile-archive':
        this.emptyIcon = require('../assets/_empty_states_archive.svg')
        this.emptyTitle = 'No archived cases yet'
        this.emptyDescription = 'Once you close a case,<br>' +
            'it will appear here'
        break
    }
  }

  updated() {
    this.initEmpty()
  }

  created() {
    this.srcArchive = Object.assign([], this.archiveItems);
    this.initEmpty()
  }

  async beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.init(to.params.archiveId, to.query)
    })
  }

  async beforeRouteUpdate(to, from, next) {
    this.unsubscribeAll()
    await this.init(to.params.archiveId, to.query)
    next()
  }

  async beforeRouteLeave(to, from, next) {
    this.unsubscribeAll()
    next()
  }
}
